import { Action, createAction, createReducer, on, props } from '@ngrx/store';
import { IRequestDTask } from '@rdlabo/odss-mobile-api/src/endpoint/functions/task/task.api';

export const taskDefault = (): IRequestDTask => ({
  attachments: [],
  slipIds: [],
  memo: null,
  mapUrl: null,
  classification: 301,
});

export const actionTask = createAction('[STORE]', props<IRequestDTask>());

const ReducerFunction = createReducer(
  taskDefault(),
  on(actionTask, (state, reload) => {
    return reload;
  }),
);

export function reducer(state: IRequestDTask, action: Action) {
  return ReducerFunction(state, action);
}
